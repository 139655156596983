// import React, { useState, useContext } from "react";
// import { Link } from "gatsby";
// import { useSelector, shallowEqual, useDispatch } from "react-redux";
// import { VID, PROJECT_LINK, PREVIEW } from "../project-config.js";
// import ProfileIcon from "../assets/img/Profile.png";
// import Modal from "../shared/components/UIElements/Modal";
// import Drawer from "@material-ui/core/Drawer";
// import LazyLoad from "react-lazyload";
// import Login from "../components/AC-LoginBox/Login";
// import { I18nContext } from "../i18n";

// import { handleLogout } from "../redux/actions/loginActions.js";

// export default function Profile({ modalOpenedByIcon, setModalOpened }) {
//   const dispatch = useDispatch();
//   const { langCode, translate } = useContext(I18nContext);

//   const [Loginstate, LoginsetState] = useState({
//     top1: false,
//     left1: false,
//     bottom1: false,
//     right1: false
//   });

//   const [AfterLoginstate, AfterLoginsetState] = useState({
//     top1: false,
//     left1: false,
//     bottom1: false,
//     right1: false
//   });

//   const toggleDrawerLogin = (side, open) => event => {
//     if (event.key === "Tab" || event.key === "Shift") {
//       return;
//     }

//     LoginsetState({ ...Loginstate, [side]: open });
//   };

//   const toggleDrawerAfterLogin = (side, open) => event => {
//     if (event.key === "Tab" || event.key === "Shift") {
//       return;
//     }

//     AfterLoginsetState({ ...AfterLoginstate, [side]: open });
//   };

//   const sideListLogin = side => (
//     <div role="presentation">
//       <Login />
//     </div>
//   );

//   const securityTokenState = useSelector(
//     state => state.loginReducer.securityToken,
//     shallowEqual
//   );
//   const loginNameState = useSelector(
//     state => state.loginReducer.loginName,
//     shallowEqual
//   );

//   const languageState = useSelector(
//     state => state.mainReducer.lang,
//     shallowEqual
//   );

//   const logout = e => {
//     e.preventDefault();
//     dispatch(handleLogout(securityTokenState));
//   };

//   const [open, setOpen] = React.useState(false);

//   React.useEffect(() => {
//     setOpen(modalOpenedByIcon);
//   }, [modalOpenedByIcon]);

//   React.useEffect(() => {
//     setModalOpened(open);
//   }, [open]);

//   const handleClick = () => {
//     setOpen(prev => !prev);
//   };

//   const handleClickAway = () => {
//     setOpen(false);
//   };

//   return loginNameState != "" ? (
//     <LazyLoad height={18} offset={100} debounce={300} fadein={true}>
//       <div
//         className="icon-wrapper"
//         onClick={
//           langCode === "ar"
//             ? toggleDrawerAfterLogin("left", true)
//             : toggleDrawerAfterLogin("right", true)
//         }

//       >
//         <img
//           src={ProfileIcon}
//           alt="Icône de profil"
//           className="img-responsive iconInfo3"
//         />
//       </div>
//       <div
//         id="login-icon-btn"
//         className="login-text-container"
//         onClick={
//           langCode === "ar"
//             ? toggleDrawerAfterLogin("left", true)
//             : toggleDrawerAfterLogin("right", true)
//         }
//         onKeyPress={
//           langCode === "ar"
//             ? toggleDrawerAfterLogin("left", true)
//             : toggleDrawerAfterLogin("right", true)
//         }
//         tabIndex="0"
//       >
//         <span
//           className="icon-action-text"
//           onClick={
//             langCode === "ar"
//               ? toggleDrawerAfterLogin("left", true)
//               : toggleDrawerAfterLogin("right", true)
//           }
//         >
//           {loginNameState}
//         </span>
//       </div>

//       <Drawer
//         className="loginState"
//         anchor={langCode === "ar" ? "left" : "right"}
//         open={langCode === "ar" ? AfterLoginstate.left : AfterLoginstate.right}
//         onClose={
//           langCode === "ar"
//             ? toggleDrawerAfterLogin("left", false)
//             : toggleDrawerAfterLogin("right", false)
//         }
//       >
//         <div className={langCode == "ar" ? "inner arabic" : "inner"}>
//           <div style={{ backgroundColor: "#000000", color: "white" }}>
//             <h6 className="minicart-title CustomerLogin">
//               {" "}
//               MyAccount
//               <i
//                 onClick={
//                   langCode === "ar"
//                     ? toggleDrawerAfterLogin("left", false)
//                     : toggleDrawerAfterLogin("right", false)
//                 }
//                 className="material-icons"
//                 style={{ cursor: "pointer", fontSize: "2.375em" }}
//               >
//                 close
//               </i>
//             </h6>
//           </div>
//           <ul className="user-profile-dropdown">
//             <a
//               href={`${PROJECT_LINK}/myaccount.html?mode=changepassword&vid=${VID}&iu=${languageState}`}
//             >
//               <li className="myaccount-box">
//                 <i className="material-icons">vpn_key</i>{" "}
//                 {translate("jsp.header_changepassword")}
//               </li>
//             </a>

//             <a
//               href={`${PROJECT_LINK}/myaccount.html?mode=vieworder&vid=${VID}&iu=${languageState}`}
//             >
//               <li className="myaccount-box">
//                 <i className="material-icons">visibility</i>{" "}
//                 {translate("jsp.header_vieworders")}
//               </li>
//             </a>
//             <a
//               href={`${PROJECT_LINK}/myaccount.html?mode=activities&vid=${VID}&iu=${languageState}`}
//             >
//               <li className="myaccount-box">
//                 <i className="material-icons">notifications</i>{" "}
//                 {translate("jsp.header_notifications")}
//               </li>
//             </a>
//             <a
//               href={`${PROJECT_LINK}/myaccount.html?mode=billingaddress&vid=${VID}&iu=${languageState}`}
//             >
//               <li className="myaccount-box">
//                 <i className="material-icons">store_mall_directory</i>{" "}
//                 {translate("jsp.header_billingaddress")}
//               </li>
//             </a>
//             <a
//               href={`${PROJECT_LINK}/myaccount.html?mode=shippingaddress&vid=${VID}&iu=${languageState}`}
//             >
//               <li className="myaccount-box">
//                 <i className="material-icons">local_shipping</i>{" "}
//                 {translate("jsp.header_shippingaddress")}
//               </li>
//             </a>
//             <a href="javascript:void(0);">
//               <li className="myaccount-box">
//                 <form name="logoutPopup" onSubmit={logout} autoComplete="on">
//                   <button type="submit">
//                     <i className="material-icons">power_settings_new</i>{" "}
//                     {translate("jsp.header_logout")}
//                   </button>
//                 </form>
//               </li>
//             </a>
//           </ul>
//         </div>
//       </Drawer>

//       {/* <Modal
//         className="fim-dropdown sign-display display-signin"
//         show={open}
//         onCancel={handleClickAway}
//         style={{ zIndex: "9999" }}
//         dropDownList={true}
//         noClass
//         noHeader
//         noFooter
//       >

//       </Modal> */}
//     </LazyLoad>
//   ) : (
//     <>
//       <div
//         className="icon-wrapper"
//         onClick={
//           langCode === "ar"
//             ? toggleDrawerLogin("left", true)
//             : toggleDrawerLogin("right", true)
//         }
//         onKeyPress={
//           langCode === "ar"
//             ? toggleDrawerAfterLogin("left", true)
//             : toggleDrawerAfterLogin("right", true)
//         }
//         tabIndex="0"
//       >
//         <img
//           src={ProfileIcon}
//           alt="Profile Icon"
//           className="img-responsive iconInfo3"
//         />
//       </div>
//       <div
//         id="login-icon-btn"
//         className="login-text-container"
//         onClick={
//           langCode === "ar"
//             ? toggleDrawerLogin("left", true)
//             : toggleDrawerLogin("right", true)
//         }
//       >
//         <span className="icon-action-text">S'identifier</span>
//       </div>
//       <Drawer
//         className="loginState"
//         anchor={langCode === "ar" ? "left" : "right"}
//         open={langCode === "ar" ? Loginstate.left : Loginstate.right}
//         onClose={
//           langCode === "ar"
//             ? toggleDrawerLogin("left", false)
//             : toggleDrawerLogin("right", false)
//         }
//       >
//         <div style={{ backgroundColor: "#000000", color: "white" }}>
//           <h6 className="minicart-title CustomerLogin">
//             {translate("CustomerLogin")}
//             <i
//               onClick={
//                 langCode === "ar"
//                   ? toggleDrawerLogin("left", false)
//                   : toggleDrawerLogin("right", false)
//               }
//               className="material-icons"
//               style={{ cursor: "pointer", fontSize: "2.375em" }}
//             >
//               close
//             </i>
//           </h6>
//         </div>
//         {langCode === "ar" ? sideListLogin("left") : sideListLogin("right")}
//       </Drawer>
//     </>
//   );
// }

import React, { useState, useContext, useRef } from "react";
import { Link } from "gatsby";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { VID, PROJECT_LINK, PREVIEW } from "../project-config.js";
import ProfileIcon from "../assets/img/Profile.png";
import Modal from "../shared/components/UIElements/Modal";
import Drawer from "@material-ui/core/Drawer";
import LazyLoad from "react-lazyload";
import Login from "../components/AC-LoginBox/Login";
import { I18nContext } from "../i18n";

import { handleLogout } from "../redux/actions/loginActions.js";

export default function Profile({ modalOpenedByIcon, setModalOpened }) {
  const dispatch = useDispatch();
  const { langCode, translate } = useContext(I18nContext);

  const [Loginstate, LoginsetState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const [AfterLoginstate, AfterLoginsetState] = useState({
    top1: false,
    left1: false,
    bottom1: false,
    right1: false
  });

  const toggleDrawerLogin = (side, open) => event => {
    if (event.key === "Tab" || event.key === "Shift") {
      return;
    }

    LoginsetState({ ...Loginstate, [side]: open });
  };

  const toggleDrawerAfterLogin = (side, open) => event => {
    if (event.key === "Tab" || event.key === "Shift") {
      return;
    }

    AfterLoginsetState({ ...AfterLoginstate, [side]: open });
  };

  const securityTokenState = useSelector(
    state => state.loginReducer.securityToken,
    shallowEqual
  );
  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  const languageState = useSelector(
    state => state.mainReducer.lang,
    shallowEqual
  );

  const logout = e => {
    e.preventDefault();
    dispatch(handleLogout(securityTokenState));
  };

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(modalOpenedByIcon);
  }, [modalOpenedByIcon]);

  React.useEffect(() => {
    setModalOpened(open);
  }, [open]);

  const closeRef = useRef(null);

  return loginNameState != "" ? (
    <LazyLoad height={18} offset={100} debounce={300} fadein={true}>
      <div className="icon-container login-icon-container">
        <div
          id="login-icon-btn"
          className="icon-wrapper"
          onClick={
            langCode === "ar"
              ? toggleDrawerAfterLogin("left", true)
              : toggleDrawerAfterLogin("right", true)
          }
          onKeyPress={
            langCode === "ar"
              ? toggleDrawerAfterLogin("left", true)
              : toggleDrawerAfterLogin("right", true)
          }
          tabIndex="0"
        >
          {/* <i className="material-icons">account_circle</i> */}
          <img
            src={ProfileIcon}
            alt="Icône de profil"
            className="img-responsive iconInfo3"
          />
        </div>
        <div
          className="login-text-container"
          onClick={
            langCode === "ar"
              ? toggleDrawerAfterLogin("left", true)
              : toggleDrawerAfterLogin("right", true)
          }
        >
          <span
            className="icon-action-text"
            onClick={
              langCode === "ar"
                ? toggleDrawerAfterLogin("left", true)
                : toggleDrawerAfterLogin("right", true)
            }
          >
            {loginNameState}
          </span>
        </div>

        <Drawer
          className="loginState"
          anchor={langCode === "ar" ? "left" : "right"}
          open={
            langCode === "ar" ? AfterLoginstate.left : AfterLoginstate.right
          }
          onClose={
            langCode === "ar"
              ? toggleDrawerAfterLogin("left", false)
              : toggleDrawerAfterLogin("right", false)
          }
        >
          <div className={langCode == "ar" ? "inner arabic" : "inner"}>
            <div style={{ backgroundColor: "#000000", color: "white" }}>
              <h6 className="minicart-title CustomerLogin">
                {" "}
                Mon compte
                <i
                  onClick={
                    langCode === "ar"
                      ? toggleDrawerAfterLogin("left", false)
                      : toggleDrawerAfterLogin("right", false)
                  }
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      AfterLoginsetState({ ...AfterLoginstate, right: false });
                    }
                  }}
                  className="material-icons closeButton"
                  style={{
                    cursor: "pointer",
                    fontSize: "2.375em",
                    color: "white"
                  }}
                  tabIndex="0"
                  ref={closeRef}
                >
                  close
                </i>
              </h6>
            </div>
            <ul className="user-profile-dropdown">
              <a
                href={`${PROJECT_LINK}/myaccount.html?mode=changepassword&vid=${VID}&iu=${languageState}`}
              >
                <li className="myaccount-box">
                  <i className="material-icons">vpn_key</i>{" "}
                  {translate("jsp.header_changepassword")}
                </li>
              </a>

              <a
                href={`${PROJECT_LINK}/myaccount.html?mode=vieworder&vid=${VID}&iu=${languageState}`}
              >
                <li className="myaccount-box">
                  <i className="material-icons">visibility</i>{" "}
                  {translate("jsp.header_vieworders")}
                </li>
              </a>
              <a
                href={`${PROJECT_LINK}/myaccount.html?mode=activities&vid=${VID}&iu=${languageState}`}
              >
                <li className="myaccount-box">
                  <i className="material-icons">notifications</i>{" "}
                  {translate("jsp.header_notifications")}
                </li>
              </a>
              <a
                href={`${PROJECT_LINK}/myaccount.html?mode=billingaddress&vid=${VID}&iu=${languageState}`}
              >
                <li className="myaccount-box">
                  <i className="material-icons">store_mall_directory</i>{" "}
                  {translate("jsp.header_billingaddress")}
                </li>
              </a>
              <a
                href={`${PROJECT_LINK}/myaccount.html?mode=shippingaddress&vid=${VID}&iu=${languageState}`}
              >
                <li className="myaccount-box">
                  <i className="material-icons">local_shipping</i>{" "}
                  {translate("jsp.header_shippingaddress")}
                </li>
              </a>
              <a href="#">
                <li className="myaccount-box">
                  <form name="logoutPopup" onSubmit={logout} autoComplete="on">
                    <button type="submit">
                      <i className="material-icons">power_settings_new</i>{" "}
                      {translate("jsp.header_logout")}
                    </button>
                  </form>
                </li>
              </a>
            </ul>
          </div>
        </Drawer>

        {/* <Modal
        className="fim-dropdown sign-display display-signin"
        show={open}
        onCancel={handleClickAway}
        style={{ zIndex: "9999" }}
        dropDownList={true}
        noClass
        noHeader
        noFooter
      >
        
      </Modal> */}
      </div>
    </LazyLoad>
  ) : (
    <>
      <div className="icon-container login-icon-container">
        <div
          id="login-icon-btn"
          tabIndex="0"
          className="icon-wrapper"
          onClick={
            langCode === "ar"
              ? toggleDrawerLogin("left", true)
              : toggleDrawerLogin("right", true)
          }
          onKeyPress={
            langCode === "ar"
              ? toggleDrawerLogin("left", true)
              : toggleDrawerLogin("right", true)
          }
        >
          {/* <i className="material-icons">account_circle</i> */}
          <img
            src={ProfileIcon}
            alt="Icône de profil"
            className="img-responsive iconInfo3"
          />
        </div>
        <div
          className="login-text-container"
          onClick={
            langCode === "ar"
              ? toggleDrawerLogin("left", true)
              : toggleDrawerLogin("right", true)
          }
        >
          <span className="icon-action-text">S'identifier</span>
        </div>
        <Drawer
          className="loginState"
          anchor={langCode === "ar" ? "left" : "right"}
          open={langCode === "ar" ? Loginstate.left : Loginstate.right}
          onClose={
            langCode === "ar"
              ? toggleDrawerLogin("left", false)
              : toggleDrawerLogin("right", false)
          }
        >
          <div style={{ backgroundColor: "#000000", color: "white" }}>
            <h6 className="minicart-title CustomerLogin">
              {translate("CustomerLogin")}
              <i
                onClick={
                  langCode === "ar"
                    ? toggleDrawerLogin("left", false)
                    : toggleDrawerLogin("right", false)
                }
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    e.preventDefault();

                    LoginsetState({ ...Loginstate, right: false });
                  }
                }}
                className="material-icons closeButton"
                style={{
                  cursor: "pointer",
                  fontSize: "2.375em",
                  color: "white"
                }}
                tabIndex="0"
                ref={closeRef}
              >
                close
              </i>
            </h6>
          </div>
          <div role="presentation">
            <Login closeRef={closeRef} />
          </div>
        </Drawer>
      </div>
    </>
  );
}
