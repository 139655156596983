import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { PROJECT_LINK, PREVIEW } from "../../../project-config";
import classes from "../styles/ADAbar.module.css";
import { Link, navigate } from "gatsby";

import { FormattedNumber } from "react-intl";
import { I18nContext } from "../../../i18n/index";
import PriceTag from "../../../shared/components/PriceTag/PriceTag";
import { handleReplaceImagesWithLargeImagesOnError } from "../../../functions/Utilities";

export default function ADAbar({ show, close, toggleWish }) {
  const wishListState = useSelector(
    state => state.wishListReducer.wishlist,
    shallowEqual
  );

  const { langCode, currency, translate, priceConvert } = React.useContext(
    I18nContext
  );

  return (
    <div onClick={close} id="adaBar" className={classes.adaCont}>
      <div className="h-con" style={{ textAlign: "center", width: "100%" }}>
        Si vous éprouvez des difficultés à accéder au contenu, veuillez nous
        contacter au 866-749-6327 ou par courriel{" "}
        <a href="mailto:cdncustserv@deluxe.ca?subject=Accessibility Support">
          cdncustserv@deluxe.ca
        </a>
        , et nous vous.{" "}
      </div>
    </div>
  );
}
