import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  createRef
} from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link } from "gatsby";
import { PREVIEW, PROJECT_LINK, VID } from "../project-config.js";
import Async from "react-code-splitting";
import { I18nContext } from "../i18n/index";
import { setHTMLElementFixedPosition } from "../functions/Utilities.js";
import SearchBarMobile from "../components/AC-Search/components/SearchBarMobile";
import LogoMobileWhite from "../assets/img/demoLogo_mobile_white.png";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import "./Styles/header.css";
import "./Styles/NavMenu.css";

export default function NavMenu({ data }) {
  const { langCode, translate } = useContext(I18nContext);

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const currentScreenWidthState = useSelector(
    state => state.mainReducer.currentScreenWidth,
    shallowEqual
  );

  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );

  const languageState = useSelector(
    state => state.mainReducer.lang,
    shallowEqual
  );

  const [navMenuOpen, setNavMenuOpen] = useState(false);

  const toggleDrawer = () => event => {
    if (
      // event &&
      // event.type === "keydown" &&
      // (event.key === "Tab" || event.key === "Shift")
      event
    ) {
      return;
    }

    setNavMenuOpen(!navMenuOpen);
  };

  useEffect(() => {
    setHTMLElementFixedPosition(navMenuOpen);
    return () => {
      setHTMLElementFixedPosition(false);
    };
  }, [navMenuOpen]);

  const handleCategoryChange = (cid, cat, parents, longdesc) => {
    // let catName = cat;
    if (isMobileState) {
      setNavMenuOpen(!navMenuOpen);
    }
    /*   console.info("handleCatChange", cid, cat, parents);
    if (cat.includes("&amp;")) {
      catName = cat.replace("&amp;", "&");
    }

    const category = navCatsState.childs.filter(c => {
      let name = c.name;
      if (name.includes("&amp;")) {
        name = name.replace("&amp;", "&");
      }

      return name.toLowerCase() === catName.toLowerCase();
    });
    //dispatch(changeCategoryName(catName));

    if (cid != "72167" && cid != "72165") {
      // 432381 - stores // cat//Stores
      // dispatch(fetchCategoryFromRender(cid, catName, parents, "", category[0]));
    } */
  };

  const [elRefs, setElRefs] = useState({});

  const testElRefs = useRef([]);
  const [hoverRef, setHoverRef] = useState([]);
  const [firstLevel, setFirstLevel] = useState("");
  const homeRef = useRef(null);
  let lengthCal = (data, tempObj) => {
    data.map(dt => {
      tempObj[dt.cid] = createRef();

      setHoverRef({ ...hoverRef, [dt.cid]: false });

      if (Object.keys(dt).includes("childs") && dt.childs.length > 0) {
        lengthCal(dt.childs, tempObj);
      }
    });
    return tempObj;
  };
  useEffect(() => {
    let tempObj = {};
    let tempObjReturn = lengthCal(data, tempObj);
    console.info("REFS *****************", data, tempObjReturn);
    setElRefs({ ...tempObjReturn });
  }, []);

  useEffect(() => {
    console.info("FIRST CHECK", firstLevel);
  }, [firstLevel]);

  const renderNavLinks = (
    cats,
    subCat = false,
    thirdLevel = false,
    fourthLevel = false,
    prevUrl = null,
    catCid = "",
    mainIndex = 0
  ) => {
    return (
      cats &&
      cats.map((cat, index) => {
        let insideIndex = mainIndex;
        if (catCid === "") {
          insideIndex = index;
        }
        let catName = cat.description;
        let arrURL = cat.URL.split(`/`);
        let lastURL = arrURL[arrURL.length - 1] || arrURL[arrURL.length - 2];
        let className = `navlink-${lastURL} navMenuItem`;
        let tempUrl = cat.URL.replace("shop/", "");
        let langTemp = tempUrl.includes(langCode) ? "" : `${langCode}/`;
        let externalUrl = tempUrl.includes("www.");
        let thirdLevelCheck = !!subCat;
        let fourthLevelCheck = !!thirdLevel;
        return (
          <li
            style={{ cursor: "pointer" }}
            key={cat.cid}
            className={className}
            role="menuitem"
          >
            {cat.childs &&
            ![
              "D&amp;eacute;marrez",
              "G&amp;eacute;rez",
              "D&amp;eacute;veloppez",
              "Votre entreprise",
              "Automobile",
              "D&eacute;taillants",
              "Entrepreneurs",
              "Fabricants et grossistes",
              "Hospitalit&eacute;",
              "Professionnels"
            ].includes(catName) &&
            cat.childs.length > 0 ? (
              <span
                ref={node => (testElRefs.current[cat.cid] = node)}
                className={subCat ? "sub-menu-span" : "main-cate sub-menu-span"}
                onKeyDown={e => {
                  if (e.keyCode === 40 && !e.shiftKey) {
                    e.preventDefault();
                    if (catCid === "") {
                      if (testElRefs.current[cat.childs[0].cid]) {
                        setFirstLevel(cat.cid);
                        testElRefs.current[cat.childs[0].cid].focus();
                      }
                    } else if (
                      cats[index + 1] &&
                      Object.keys(cats[index + 1]).includes("cid")
                    ) {
                      if (testElRefs.current[cats[index + 1].cid]) {
                        testElRefs.current[cats[index + 1].cid].focus();
                      }
                    } else if (
                      index === cats.length - 1 &&
                      catName.includes("Quickbooks Cheques")
                    ) {
                      testElRefs.current["custom-cheque"].focus();
                    }
                  } else if (e.keyCode === 38 && !e.shiftKey) {
                    e.preventDefault();
                    if (catCid === "") {
                    } else if (
                      cats[index - 1] &&
                      Object.keys(cats[index - 1]).includes("cid")
                    ) {
                      if (testElRefs.current[cats[index - 1].cid]) {
                        testElRefs.current[cats[index - 1].cid].focus();
                      }
                    }
                  } else if (e.keyCode === 39 && !e.shiftKey) {
                    if (
                      catCid !== "" &&
                      Object.keys(cat).includes("childs") &&
                      cat.childs.length > 0 &&
                      Object.keys(cat.childs[0]).includes("cid") &&
                      testElRefs.current[cat.childs[0].cid]
                    ) {
                      testElRefs.current[cat.childs[0].cid].focus();
                    } else if (catCid === "" && index < cats.length - 1) {
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      setHoverRef({ ...tempObj });
                      testElRefs.current[cats[index + 1].cid].focus();
                    }
                  } else if (e.keyCode === 37 && !e.shiftKey) {
                    if (catCid !== "" && testElRefs.current[catCid]) {
                      testElRefs.current[catCid].focus();
                    } else if (index !== 0) {
                      testElRefs.current[cats[index - 1].cid].focus();
                    } else if (index === 0 && homeRef && homeRef.current) {
                      homeRef.current.focus();
                    }
                  }
                  if (
                    (e.keyCode === 40 || e.keyCode === 39) &&
                    Object.keys(cat).includes("childs") &&
                    cat.childs.length > 0
                  ) {
                    e.preventDefault();
                    if (catCid === "" && e.keyCode === 40) {
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      tempObj[cat.cid] = true;
                      setHoverRef({ ...tempObj });
                    } else if (catCid !== "" && e.keyCode === 39) {
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj)
                        .filter(f => ![catCid, firstLevel].includes(f))
                        .map(l => (tempObj[l] = false));
                      tempObj[cat.cid] = true;
                      setHoverRef({ ...tempObj });
                    }
                  } else if (
                    (e.keyCode === 38 || e.keyCode === 37) &&
                    !thirdLevel
                  ) {
                    e.preventDefault();
                    if (catCid === "" && e.keyCode === 38) {
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      tempObj[cat.cid] = false;
                      setHoverRef({ ...tempObj });
                    } else if (e.keyCode === 37) {
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj)
                        .filter(f => f !== catCid)
                        .map(l => (tempObj[l] = false));
                      tempObj[cat.cid] = false;
                      setHoverRef({ ...tempObj });
                    }
                  } else if (e.shiftKey && e.keyCode === 9) {
                    let tempObj = { ...hoverRef };
                    Object.keys(tempObj).map(l => (tempObj[l] = false));
                    setHoverRef({ ...tempObj });

                    if (insideIndex !== 0) {
                      e.preventDefault();

                      testElRefs.current[data[insideIndex - 1].cid].focus();
                    }
                  } else if (!e.shiftKey && e.keyCode === 9) {
                    if (data.length - 1 >= insideIndex + 1) {
                      e.preventDefault();
                      let tempObj = {
                        ...hoverRef
                      };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      setHoverRef({
                        ...tempObj
                      });
                      testElRefs.current[data[insideIndex + 1].cid].focus();
                    } else {
                      let tempObj = {
                        ...hoverRef
                      };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      setHoverRef({
                        ...tempObj
                      });
                    }
                  } else if (e.keyCode === 27) {
                    e.preventDefault();
                    let tempObj = { ...hoverRef };

                    Object.keys(tempObj).map(l => (tempObj[l] = false));
                    setHoverRef({ ...tempObj });
                    testElRefs.current[data[insideIndex].cid].focus();
                  } else if (e.keyCode !== 9 && e.keyCode !== 13) {
                    e.preventDefault();
                  }
                }}
                tabIndex="0"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: catName.replace(/&amp;/gm, "&")
                  }}
                />
                {cat.childs && cat.childs.length > 0 ? (
                  !subCat ? (
                    <i className="material-icons">expand_more</i>
                  ) : (
                    <i className="material-icons">chevron_right</i>
                  )
                ) : null}
              </span>
            ) : externalUrl ? (
              <a
                ref={node => (testElRefs.current[cat.cid] = node)}
                onKeyDown={e => {
                  if (e.keyCode === 40 && !e.shiftKey) {
                    e.preventDefault();
                    if (catCid === "") {
                      if (testElRefs.current[cat.childs[0].cid]) {
                        setFirstLevel(cat.cid);
                        testElRefs.current[cat.childs[0].cid].focus();
                      }
                    } else if (
                      cats[index + 1] &&
                      Object.keys(cats[index + 1]).includes("cid")
                    ) {
                      if (testElRefs.current[cats[index + 1].cid]) {
                        testElRefs.current[cats[index + 1].cid].focus();
                      }
                    } else if (
                      index === cats.length - 1 &&
                      catName.includes("Quickbooks Cheques")
                    ) {
                      testElRefs.current["custom-cheque"].focus();
                    }
                  } else if (e.keyCode === 38 && !e.shiftKey) {
                    e.preventDefault();
                    if (catCid === "") {
                    } else if (
                      cats[index - 1] &&
                      Object.keys(cats[index - 1]).includes("cid")
                    ) {
                      if (testElRefs.current[cats[index - 1].cid]) {
                        testElRefs.current[cats[index - 1].cid].focus();
                      }
                    }
                  } else if (e.keyCode === 39 && !e.shiftKey) {
                    if (
                      catCid !== "" &&
                      Object.keys(cat).includes("childs") &&
                      cat.childs.length > 0 &&
                      Object.keys(cat.childs[0]).includes("cid") &&
                      testElRefs.current[cat.childs[0].cid]
                    ) {
                      testElRefs.current[cat.childs[0].cid].focus();
                    } else if (catCid === "" && index < cats.length - 1) {
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      setHoverRef({ ...tempObj });
                      testElRefs.current[cats[index + 1].cid].focus();
                    }
                  } else if (e.keyCode === 37 && !e.shiftKey) {
                    if (catCid !== "" && testElRefs.current[catCid]) {
                      testElRefs.current[catCid].focus();
                    } else if (index !== 0) {
                      testElRefs.current[cats[index - 1].cid].focus();
                    } else if (index === 0 && homeRef && homeRef.current) {
                      homeRef.current.focus();
                    }
                  }
                  if (
                    (e.keyCode === 40 || e.keyCode === 39) &&
                    Object.keys(cat).includes("childs") &&
                    cat.childs.length > 0
                  ) {
                    e.preventDefault();
                    if (catCid === "" && e.keyCode === 40) {
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      tempObj[cat.cid] = true;
                      setHoverRef({ ...tempObj });
                    } else if (catCid !== "" && e.keyCode === 39) {
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj)
                        .filter(f => ![catCid, firstLevel].includes(f))
                        .map(l => (tempObj[l] = false));
                      tempObj[cat.cid] = true;
                      setHoverRef({ ...tempObj });
                    }
                  } else if (
                    (e.keyCode === 38 || e.keyCode === 37) &&
                    !thirdLevel
                  ) {
                    e.preventDefault();
                    if (catCid === "" && e.keyCode === 38) {
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      tempObj[cat.cid] = false;
                      setHoverRef({ ...tempObj });
                    } else if (e.keyCode === 37) {
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj)
                        .filter(f => f !== catCid)
                        .map(l => (tempObj[l] = false));
                      tempObj[cat.cid] = false;
                      setHoverRef({ ...tempObj });
                    }
                  } else if (e.shiftKey && e.keyCode === 9) {
                    let tempObj = { ...hoverRef };
                    Object.keys(tempObj).map(l => (tempObj[l] = false));
                    setHoverRef({ ...tempObj });

                    if (insideIndex !== 0) {
                      e.preventDefault();
                      testElRefs.current[data[insideIndex - 1].cid].focus();
                    }
                  } else if (!e.shiftKey && e.keyCode === 9) {
                    if (data.length - 1 >= insideIndex + 1) {
                      e.preventDefault();
                      let tempObj = {
                        ...hoverRef
                      };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      setHoverRef({
                        ...tempObj
                      });
                      testElRefs.current[data[insideIndex + 1].cid].focus();
                    } else {
                      let tempObj = {
                        ...hoverRef
                      };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      setHoverRef({
                        ...tempObj
                      });
                    }
                  } else if (e.keyCode === 27) {
                    e.preventDefault();
                    let tempObj = { ...hoverRef };

                    Object.keys(tempObj).map(l => (tempObj[l] = false));
                    setHoverRef({ ...tempObj });
                    testElRefs.current[data[insideIndex].cid].focus();
                  } else if (e.keyCode !== 9 && e.keyCode !== 13) {
                    e.preventDefault();
                  }
                }}
                href={tempUrl.includes("http") ? tempUrl : "//" + tempUrl}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: catName.replace(/&amp;/gm, "&")
                  }}
                />
                <small>(opens in new tab)</small>
              </a>
            ) : (
              <Link
                ref={node => (testElRefs.current[cat.cid] = node)}
                onClick={() => handleCategoryChange()}
                onKeyDown={e => {
                  if (e.keyCode === 40 && !e.shiftKey) {
                    e.preventDefault();
                    if (catCid === "") {
                      if (testElRefs.current[cat.childs[0].cid]) {
                        setFirstLevel(cat.cid);
                        testElRefs.current[cat.childs[0].cid].focus();
                      }
                    } else if (
                      cats[index + 1] &&
                      Object.keys(cats[index + 1]).includes("cid")
                    ) {
                      if (testElRefs.current[cats[index + 1].cid]) {
                        testElRefs.current[cats[index + 1].cid].focus();
                      }
                    } else if (
                      index === cats.length - 1 &&
                      catName.includes("Quickbooks Cheques")
                    ) {
                      testElRefs.current["custom-cheque"].focus();
                    }
                  } else if (e.keyCode === 38 && !e.shiftKey) {
                    e.preventDefault();
                    if (catCid === "") {
                    } else if (
                      cats[index - 1] &&
                      Object.keys(cats[index - 1]).includes("cid")
                    ) {
                      if (testElRefs.current[cats[index - 1].cid]) {
                        testElRefs.current[cats[index - 1].cid].focus();
                      }
                    }
                  } else if (e.keyCode === 39 && !e.shiftKey) {
                    if (
                      catCid !== "" &&
                      Object.keys(cat).includes("childs") &&
                      cat.childs.length > 0 &&
                      Object.keys(cat.childs[0]).includes("cid") &&
                      testElRefs.current[cat.childs[0].cid]
                    ) {
                      testElRefs.current[cat.childs[0].cid].focus();
                    } else if (catCid === "" && index < cats.length - 1) {
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      setHoverRef({ ...tempObj });
                      testElRefs.current[cats[index + 1].cid].focus();
                    }
                  } else if (e.keyCode === 37 && !e.shiftKey) {
                    if (catCid !== "" && testElRefs.current[catCid]) {
                      testElRefs.current[catCid].focus();
                    } else if (index !== 0) {
                      testElRefs.current[cats[index - 1].cid].focus();
                    } else if (index === 0 && homeRef && homeRef.current) {
                      homeRef.current.focus();
                    }
                  }
                  if (
                    (e.keyCode === 40 || e.keyCode === 39) &&
                    Object.keys(cat).includes("childs") &&
                    cat.childs.length > 0
                  ) {
                    e.preventDefault();
                    if (catCid === "" && e.keyCode === 40) {
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      tempObj[cat.cid] = true;
                      setHoverRef({ ...tempObj });
                    } else if (catCid !== "" && e.keyCode === 39) {
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj)
                        .filter(f => ![catCid, firstLevel].includes(f))
                        .map(l => (tempObj[l] = false));
                      tempObj[cat.cid] = true;
                      setHoverRef({ ...tempObj });
                    }
                  } else if (
                    (e.keyCode === 38 || e.keyCode === 37) &&
                    !thirdLevel
                  ) {
                    e.preventDefault();
                    if (catCid === "" && e.keyCode === 38) {
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      tempObj[cat.cid] = false;
                      setHoverRef({ ...tempObj });
                    } else if (e.keyCode === 37) {
                      let tempObj = { ...hoverRef };
                      Object.keys(tempObj)
                        .filter(f => f !== catCid)
                        .map(l => (tempObj[l] = false));
                      tempObj[cat.cid] = false;
                      setHoverRef({ ...tempObj });
                    }
                  } else if (e.shiftKey && e.keyCode === 9) {
                    let tempObj = { ...hoverRef };
                    Object.keys(tempObj).map(l => (tempObj[l] = false));
                    setHoverRef({ ...tempObj });

                    if (insideIndex !== 0) {
                      e.preventDefault();
                      testElRefs.current[data[insideIndex - 1].cid].focus();
                    }
                  } else if (!e.shiftKey && e.keyCode === 9) {
                    if (data.length - 1 >= insideIndex + 1) {
                      e.preventDefault();
                      let tempObj = {
                        ...hoverRef
                      };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      setHoverRef({
                        ...tempObj
                      });
                      testElRefs.current[data[insideIndex + 1].cid].focus();
                    } else {
                      let tempObj = {
                        ...hoverRef
                      };
                      Object.keys(tempObj).map(l => (tempObj[l] = false));
                      setHoverRef({
                        ...tempObj
                      });
                    }
                  } else if (e.keyCode === 27) {
                    e.preventDefault();
                    let tempObj = { ...hoverRef };

                    Object.keys(tempObj).map(l => (tempObj[l] = false));
                    setHoverRef({ ...tempObj });
                    testElRefs.current[data[insideIndex].cid].focus();
                  } else if (e.keyCode !== 9 && e.keyCode !== 13) {
                    e.preventDefault();
                  }
                }}
                className={subCat ? "" : "main-cate"}
                to={
                  langCode !== "en" ? "/" + langTemp + tempUrl : "/" + tempUrl
                }
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: catName.replace(/&amp;/gm, "&")
                  }}
                />
                {![
                  "Automobile",
                  "D&eacute;taillants",
                  "Entrepreneurs",
                  "Fabricants et grossistes",
                  "Hospitalit&eacute;",
                  "Professionnels"
                ].includes(catName) &&
                cat.childs &&
                cat.childs.length > 0 ? (
                  !subCat ? (
                    <i className="material-icons">expand_more</i>
                  ) : (
                    <i className="material-icons">chevron_right</i>
                  )
                ) : null}
              </Link>
            )}

            {![
              "Automobile",
              "D&eacute;taillants",
              "Entrepreneurs",
              "Fabricants et grossistes",
              "Hospitalit&eacute;",
              "Professionnels"
            ].includes(catName) &&
            cat.childs &&
            cat.childs.length > 0 ? (
              <ul
                className={
                  fourthLevelCheck
                    ? `fourth-level-menu ${
                        hoverRef[cat.cid] ? "displayAccessible" : ""
                      }`
                    : thirdLevelCheck
                    ? `third-level-menu ${
                        hoverRef[cat.cid] ? "displayAccessible" : ""
                      }`
                    : `second-level-menu ${
                        hoverRef[cat.cid] ? "displayAccessible" : ""
                      }`
                }
              >
                {thirdLevelCheck
                  ? renderNavLinks(
                      cat.childs,
                      true,
                      true,
                      fourthLevelCheck,
                      tempUrl,
                      cat.cid,
                      insideIndex
                    )
                  : renderNavLinks(
                      cat.childs,
                      true,
                      thirdLevelCheck,
                      fourthLevelCheck,
                      tempUrl,
                      cat.cid,
                      insideIndex
                    )}
              </ul>
            ) : null}
          </li>
        );
      })
    );
  };

  const renderStoreLinks = stores => {
    return (
      <div className="navlink-sublinks-container">
        {stores.map((store, index) => {
          let storeLink = store.replace(/ /g, "-").toLowerCase();
          return (
            <Link
              className="icons-container"
              key={index}
              to={
                langCode !== "en"
                  ? PREVIEW + "/" + langCode + `/stores/${storeLink}`
                  : PREVIEW + `/stores/${storeLink}`
              }
            >
              {store}
            </Link>
          );
        })}
      </div>
    );
  };
  return (
    <nav className="categoryStructure">
      {isMobileState ? (
        <React.Fragment>
          <i
            open={navMenuOpen}
            className="material-icons mobile-nav-icon mobileDisplayy"
            onClick={toggleDrawer()}
          >
            <span></span>
          </i>
          <div className="mobile-nav-bar-container" open={navMenuOpen}>
            <div
              id="nav-menu-drawer"
              className={
                langCode === "ar"
                  ? "rtl nav-wrapper actual-nav scroll-bar-thin-style"
                  : "nav-wrapper actual-nav scroll-bar-thin-style"
              }
            >
              <div className="nav-content-wrapper">
                <div className="search-bar-container">
                  <div className="search-bar-wrapper">
                    <SearchBarMobile />
                  </div>
                </div>
                <ul className="staticMenu">
                  <li style={{ color: "#2aa841" }}>
                    <Link
                      onClick={() => {
                        if (isMobileState) {
                          setNavMenuOpen(!navMenuOpen);
                        }
                      }}
                      className="menuCat1"
                      to={"/"}
                    >
                      {translate("Home").toUpperCase()}
                    </Link>
                  </li>
                  {navCatsState &&
                    navCatsState.childs &&
                    navCatsState.childs.length > 0 &&
                    navCatsState.childs.map(cat => {
                      let catName = cat.name.replace("&amp;", "&");
                      let tempUrl = cat.URL;
                      tempUrl = tempUrl;

                      tempUrl = tempUrl.replace(`${langCode}/shop`, "shop");
                      return (
                        <li key={cat.cid}>
                          <Link
                            onClick={() =>
                              handleCategoryChange(
                                cat.cid,
                                catName,
                                [[catName, cat.cid, cat.URL]],
                                cat.longdesc
                              )
                            }
                            className="menuCat"
                            style={{
                              textTransform:
                                catName != "by Brand" && catName != "by Style"
                                  ? ""
                                  : null
                            }}
                            to={"/" + tempUrl}
                          >
                            {console.info("tempUrl", tempUrl)}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: catName
                              }}
                            />
                          </Link>
                        </li>
                      );
                    })}
                </ul>
                <ul className="staticMenu">
                  <li>
                    <a href="https://www.avetticommerce.com/" target="_blank">
                      {translate("vm.footer_about").toUpperCase()}
                    </a>
                  </li>
                  <li>
                    <Link onClick={toggleDrawer()} to={"/login"}>
                      {translate("js.header.login").toUpperCase()}
                    </Link>
                  </li>
                  {/*  <li>
                    <a
                      href={`${PROJECT_LINK}/my-account.html?mode=customerinfo&vid=${VID}`}
                    >
                      {translate("js.header.login").toUpperCase()}
                    </a>
                </li> */}
                  <li>
                    <a
                      href={`${PROJECT_LINK}/basket.html?vid=${VID}&iu=${languageState}`}
                    >
                      {translate("js.header.cart").toUpperCase()}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${PROJECT_LINK}/signin.html?vid=${VID}&mt=1&iu=${languageState}`}
                    >
                      {translate("Myfavourites").toUpperCase()}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className="ab">
          <ul className="nav-icons-container cate top-level-menu">
            {renderNavLinks(data)}
          </ul>
        </div>
      )}
    </nav>
  );
}
