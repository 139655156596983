import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useLayoutEffect
} from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link } from "gatsby";

import NavMenu from "./NavMenu.jsx";
import Drawer from "@material-ui/core/Drawer";
import MiniCart from "../components/AC-MiniCart/MiniCart.jsx";

import Profile from "./Profile.jsx";
import { setHTMLElementFixedPosition } from "../functions/Utilities.js";
import SearchBarMobile from "../components/AC-Search/components/SearchBarMobile";
import WishList from "../components/AC-WishList/components/WishListCustomModal.jsx";
import { I18nContext } from "../i18n/index";

import ADAbar from "../components/AC-ADAbar/components/ADAbar";

import Async from "react-code-splitting";

import Bag from "../assets/img/bag.png";
import { fetchingMenuSuccess } from "../redux/actions/menuActions.js";
import { useLocation } from "@reach/router";
import { handleMobileOrDesktop } from "../redux/actions/mainActions.js";
import { FormattedNumber } from "react-intl";
import { updateBrandsGeolocation } from "../redux/actions/geoLocationActions.js";
import { useStaticQuery, graphql } from "gatsby";
import logo from "../assets/img/logoFoot.png";
import Img from "gatsby-image";
const SearchBar = () => <Async load={import("./SearchHelper")} />;

const GeolocationPermission = () => (
  <Async
    load={import(
      "../components/AC-Notifications/GeolocationPermission/GeolocationPermission"
    )}
  />
);
export default function MenuTopBar({ data, brands }) {
  /*   const dataImg = useStaticQuery(graphql`
    query {
      Logo: file(relativePath: { eq: "logoFoot.png" }) {
        childImageSharp {
          fixed(width: 140) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `); */

  const dispatch = useDispatch();
  const location = useLocation();
  const { translate, currency, priceConvert } = useContext(I18nContext);

  useEffect(() => {
    const navCats = {
      name: "Shop",
      vid: "20180521148",
      cid: "72181",
      thumbnail: "No Image",
      image: "No Image",
      position: "1",
      description: "Shop",
      metadescription:
        "This is a Demo Item. This product demonstrates item options. As options on the right side of the Micro Vault Midi, as an entry-level portable storage solution, is ideally suited to plug into a laptop",
      metakeywords: "Lenovo IdeaPad S110 Golf Shirts Sony Micro Vault Midi 4GB",
      URL: "shop",
      childs: [...data]
    };
    dispatch(fetchingMenuSuccess(navCats));

    //BRANDS GEOLOCATION
    dispatch(updateBrandsGeolocation(brands));
  }, []);
  const langCode = useSelector(state => state.mainReducer.lang, shallowEqual);

  const [loginModalOpened, setLoginModalOpened] = useState(false);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const suppliersBasketState = useSelector(
    state => state.sessionReducer.suppliersBasket,
    shallowEqual
  );

  const geolocationRequestState = useSelector(
    state => state.handlersReducer.geolocationRequest,
    shallowEqual
  );
  const suppliersBasketCountState =
    suppliersBasketState &&
    Object.keys(suppliersBasketState).length > 0 &&
    Object.keys(suppliersBasketState).reduce((a, key) => {
      a += suppliersBasketState[key].products.length;
      return a;
    }, 0);

  const basketState = useSelector(
    state => state.sessionReducer.basket,
    shallowEqual
  );

  const basketCountState = basketState && basketState.itemsCount;

  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );
  const languageState = useSelector(
    state => state.mainReducer.lang,
    shallowEqual
  );
  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  //#region screen width listener starts
  let initialMobile;
  if (typeof window !== "undefined") {
    initialMobile = window.innerWidth;
  }

  const [currentScreenWidth, setCurrentScreenWidth] = useState(initialMobile);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleResize = () => {
        setCurrentScreenWidth(window.innerWidth);
      };
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  let isMobile;
  const mobileSize = 768;
  useEffect(() => {
    if (currentScreenWidth > mobileSize) {
      isMobile = false;
    } else {
      isMobile = true;
    }
    dispatch(handleMobileOrDesktop({ isMobile, currentScreenWidth }));
  }, [currentScreenWidth]);

  const toggleDrawerMini = (side, open) => event => {
    if (event.key === "Tab" || event.key === "Shift") {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div role="presentation" onClick={toggleDrawerMini(side, false)}>
      <MiniCart
        close={() => toggleDrawerMini(side, false)}
        setState={setState}
        state={state}
      />
    </div>
  );

  const [navMenuOpen, setNavMenuOpen] = useState(false);

  const toggleDrawer = () => {
    setNavMenuOpen(!navMenuOpen);
  };

  useEffect(() => {
    setHTMLElementFixedPosition(navMenuOpen);
    return () => {
      setHTMLElementFixedPosition(false);
    };
  }, [navMenuOpen]);

  const handleCategoryChange = (cid, cat, parents, longdesc) => {
    if (isMobileState) {
      setNavMenuOpen(!navMenuOpen);
    }
  };

  const handleOpenModalToChangeDistance = () => {
    const button = document.getElementById("locationChangeBtn");
    if (button) button.click();
  };

  const renderMyCartTextOrTheSumOfTheCart = () => {
    if (suppliersBasketCountState > 0) {
      let sumOfAllProducts = Object.keys(suppliersBasketState).reduce(
        (a, key) => {
          a += suppliersBasketState[key].products.reduce((acc, product) => {
            acc += product.price * product.qty;
            return acc;
          }, 0);
          return a;
        },
        0
      );

      return (
        <FormattedNumber
          value={sumOfAllProducts / priceConvert}
          style="currency"
          currency={currency}
        >
          {value => <span style={{ fontSize: "1.125em" }}>{value}</span>}
        </FormattedNumber>
      );
    }
    return "Mon Panier";
  };

  const testElRefs = useRef([]);
  const [menuState, setMenuState] = useState(null);
  const [showMenuState, setShowMenuState] = useState("main");
  const [parentMenu, setParentMenu] = useState({
    title: "Main Menu",
    menu: "main",
    parent: [],
    subTitle: ""
  });
  let lengthCal = (data, tempObj, cid, main = false, description) => {
    data.map(dt => {
      if (main) {
        tempObj = {
          ...tempObj,
          main: [
            ...tempObj.main,
            { ...dt, return: { title: description, menu: "main" } }
          ]
        };
      } else {
        if (Object.keys(tempObj).includes(cid) && cid !== "") {
          tempObj = {
            ...tempObj,
            [cid]: [
              ...tempObj[cid],
              { ...dt, return: { title: description, menu: cid } }
            ]
          };
        } else {
          tempObj = {
            ...tempObj,
            [cid]: [{ ...dt, return: { title: description, menu: cid } }]
          };
        }
      }
      if (Object.keys(dt).includes("childs") && dt.childs.length > 0) {
        tempObj = lengthCal(dt.childs, tempObj, dt.cid, false, dt.description);
      }
    });
    return tempObj;
  };
  useEffect(() => {
    let tempObj = { main: [] };
    let tempObjReturn = lengthCal(data, tempObj, "", true, "Main Menu");
    console.log("HEYYO MENU", data, tempObjReturn);
    setMenuState(tempObjReturn);
  }, []);

  useEffect(() => {
    console.info("HEYYO MENU", parentMenu);
  }, [parentMenu]);

  const closeRef = useRef(null);
  const lastRef = useRef(null);
  const lastRef1 = useRef(null);
  const lastRef2 = useRef(null);
  const searchRef = useRef(null);
  const headRef = useRef(null);

  useLayoutEffect(() => {
    if (
      testElRefs &&
      menuState &&
      showMenuState &&
      menuState[showMenuState] &&
      menuState[showMenuState].length > 0 &&
      testElRefs.current &&
      Object.keys(menuState[showMenuState][0].cid) &&
      testElRefs.current[menuState[showMenuState][0].cid]
    ) {
      testElRefs.current[menuState[showMenuState][0].cid].focus();
    }
  }, [showMenuState]);
  return (
    <>
      {!navMenuOpen && (
        <>
          <ADAbar />
          <div className="top-bar">
            <span>
              Appelez-nous au{" "}
              <a href="tel:18667496327" aria-label="1 8 6 6. 7 4 9. 6 2 3 7.">
                1-866-749-6327
              </a>
            </span>
            <a
              className="english-link"
              href="https://www.deluxe.ca/canEcat/shop/"
              target="_blank"
              rel="noopener"
              onKeyDown={e => {
                if (e.keyCode === 9 && isMobileState) {
                  e.preventDefault();
                  closeRef.current.focus();
                }
              }}
            >
              ENGLISH (opens in new tab)
            </a>
          </div>
        </>
      )}
      <nav className="nav-extended" style={{ background: "#fff" }}>
        <div className="nav-wrapper">
          <div className="header-container" style={{ height: "auto" }}>
            {isMobileState ? (
              <React.Fragment>
                <div className="icon-container">
                  <div className="icon-wrapper">
                    <i
                      aria-label={
                        navMenuOpen ? "Close the menu" : "Open the menu"
                      }
                      aria-hidden={navMenuOpen ? false : true}
                      ref={closeRef}
                      id="mobile-nav-icon"
                      open={navMenuOpen}
                      className="material-icons mobile-nav-icon focusIconFr"
                      tabIndex="0"
                      onClick={() => {
                        toggleDrawer();
                        setShowMenuState("main");
                        setParentMenu({
                          title: "Main Menu",
                          menu: "main",
                          parent: [],
                          subTitle: ""
                        });
                      }}
                      onKeyDown={e => {
                        if (e.keyCode === 13) {
                          e.preventDefault();
                          toggleDrawer();
                          setShowMenuState("main");
                          setParentMenu({
                            title: "Main Menu",
                            menu: "main",
                            parent: [],
                            subTitle: ""
                          });
                        } else if (e.keyCode === 27) {
                          e.preventDefault();
                          toggleDrawer();
                          setShowMenuState("main");
                          setParentMenu({
                            title: "Main Menu",
                            menu: "main",
                            parent: [],
                            subTitle: ""
                          });

                          closeRef.current.focus();
                        } else if (e.keyCode === 40 && navMenuOpen) {
                          e.preventDefault();
                          searchRef.current.focus();
                        } else if (e.keyCode === 38 && navMenuOpen) {
                          e.preventDefault();
                          lastRef.current.focus();
                        } else if (
                          e.shiftKey &&
                          e.keyCode === 9 &&
                          navMenuOpen
                        ) {
                          e.preventDefault();
                          lastRef.current.focus();
                        }
                      }}
                    >
                      <span></span>
                    </i>
                  </div>
                </div>
                <div className="mobile-nav-bar-outer-container">
                  <div
                    className="mobile-nav-bar-container"
                    open={navMenuOpen}
                    aria-hidden={navMenuOpen ? false : true}
                  >
                    <div
                      id="nav-menu-drawer"
                      className={
                        langCode === "ar"
                          ? "rtl nav-wrapper actual-nav scroll-bar-thin-style"
                          : "nav-wrapper actual-nav scroll-bar-thin-style"
                      }
                    >
                      <div className="nav-content-wrapper">
                        <div className="search-bar-container">
                          <div className="search-bar-wrapper">
                            <SearchBarMobile
                              navMenuOpen={navMenuOpen}
                              searchRef={searchRef}
                              headRef={headRef}
                              closeRef={closeRef}
                            />
                          </div>
                        </div>
                        <ul
                          className="staticMenu"
                          role="menubar"
                          aria-labelledby="Main Menu"
                        >
                          {showMenuState === "main" ? (
                            <li style={{ color: "#2aa841" }}>
                              <Link
                                role="menuitem"
                                tabIndex={navMenuOpen ? "0" : "-1"}
                                onClick={() => {
                                  if (isMobileState) {
                                    setNavMenuOpen(!navMenuOpen);
                                  }
                                }}
                                onKeyDown={e => {
                                  if (e.keyCode === 27) {
                                    e.preventDefault();
                                    toggleDrawer();
                                    setShowMenuState("main");
                                    setParentMenu({
                                      title: "Main Menu",
                                      menu: "main",
                                      parent: [],
                                      subTitle: ""
                                    });

                                    closeRef.current.focus();
                                  } else if (e.keyCode === 40) {
                                    e.preventDefault();
                                    if (
                                      menuState[showMenuState] &&
                                      menuState[showMenuState].length > 0
                                    ) {
                                      testElRefs.current[
                                        menuState[showMenuState][0].cid
                                      ].focus();
                                    }
                                  } else if (e.keyCode === 38) {
                                    e.preventDefault();
                                    searchRef.current.focus();
                                  }
                                }}
                                className="menuCat1"
                                to={"/"}
                                ref={headRef}
                              >
                                {translate("Home").toUpperCase()}
                              </Link>
                            </li>
                          ) : (
                            <li
                              style={{
                                color: "#2aa841",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                              }}
                            >
                              <i
                                className="material-icons focusIcon"
                                role="menuitem"
                                ref={headRef}
                                style={{ color: "white" }}
                                tabIndex={navMenuOpen ? "0" : "-1"}
                                onClick={e => {
                                  let parents = [...parentMenu.parent];
                                  setShowMenuState(
                                    parentMenu.parent[
                                      parentMenu.parent.length - 1
                                    ]
                                  );
                                  parents.pop();

                                  setParentMenu({
                                    ...parentMenu,
                                    menu:
                                      parentMenu.parent[
                                        parentMenu.parent.length - 1
                                      ],
                                    parent: parents
                                  });
                                }}
                                onKeyDown={e => {
                                  console.log("ESC", e.keyCode);
                                  if (e.keyCode === 13) {
                                    e.preventDefault();
                                    let parents = [...parentMenu.parent];
                                    setShowMenuState(
                                      parentMenu.parent[
                                        parentMenu.parent.length - 1
                                      ]
                                    );
                                    parents.pop();

                                    setParentMenu({
                                      ...parentMenu,
                                      menu:
                                        parentMenu.parent[
                                          parentMenu.parent.length - 1
                                        ],
                                      parent: parents
                                    });
                                  } else if (e.keyCode === 27) {
                                    e.preventDefault();
                                    toggleDrawer();
                                    setShowMenuState("main");
                                    setParentMenu({
                                      title: "Main Menu",
                                      menu: "main",
                                      parent: [],
                                      subTitle: ""
                                    });

                                    closeRef.current.focus();
                                  } else if (e.keyCode === 40) {
                                    e.preventDefault();
                                    if (
                                      menuState[showMenuState] &&
                                      menuState[showMenuState].length > 0
                                    ) {
                                      testElRefs.current[
                                        menuState[showMenuState][0].cid
                                      ].focus();
                                    }
                                  } else if (e.keyCode === 38) {
                                    e.preventDefault();
                                    searchRef.current.focus();
                                  } else if (e.keyCode === 37) {
                                    e.preventDefault();
                                    if (showMenuState !== "main") {
                                      e.preventDefault();
                                      let parents = [...parentMenu.parent];
                                      setShowMenuState(
                                        parentMenu.parent[
                                          parentMenu.parent.length - 1
                                        ]
                                      );
                                      parents.pop();

                                      setParentMenu({
                                        ...parentMenu,
                                        menu:
                                          parentMenu.parent[
                                            parentMenu.parent.length - 1
                                          ],
                                        parent: parents
                                      });
                                    }
                                  }
                                }}
                              >
                                chevron_left
                              </i>{" "}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    parentMenu.menu === "main" ||
                                    parentMenu.parent.length === 1
                                      ? "Main Menu"
                                      : parentMenu.subTitle
                                }}
                              />
                            </li>
                          )}

                          {menuState !== null &&
                            menuState[showMenuState] &&
                            menuState[showMenuState].length > 0 &&
                            menuState[showMenuState].map((cat, index) => {
                              let catName = cat.description
                                .split("&amp;eacute;")
                                .join("é")
                                .split("&eacute;")
                                .join("é")
                                .split("&egrave;")
                                .join("è")
                                .split("&#39;")
                                .join("'")
                                .split("&#174;")
                                .join("®")
                                .split("&amp;")
                                .join("&");

                              console.info("YOYOYO", catName, cat.description);
                              let tempUrl = cat.URL;
                              tempUrl = tempUrl;

                              tempUrl = tempUrl.replace(
                                `${langCode}/shop`,
                                "shop"
                              );

                              tempUrl = tempUrl.replace(`shop`, "");
                              return (
                                <li
                                  key={cat.cid}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                  }}
                                  to={tempUrl}
                                >
                                  <Link
                                    ref={node =>
                                      (testElRefs.current[cat.cid] = node)
                                    }
                                    tabIndex={navMenuOpen ? "0" : "-1"}
                                    onClick={() =>
                                      handleCategoryChange(
                                        cat.cid,
                                        catName,
                                        [[catName, cat.cid, cat.URL]],
                                        ""
                                      )
                                    }
                                    onKeyDown={e => {
                                      if (e.keyCode === 27) {
                                        e.preventDefault();
                                        toggleDrawer();
                                        setShowMenuState("main");
                                        setParentMenu({
                                          title: "Main Menu",
                                          menu: "main",
                                          parent: [],
                                          subTitle: ""
                                        });

                                        closeRef.current.focus();
                                      } else if (
                                        e.keyCode === 39 &&
                                        Object.keys(cat).includes("childs") &&
                                        cat.childs.length > 0
                                      ) {
                                        e.preventDefault();
                                        if (showMenuState !== "main") {
                                          setParentMenu({
                                            ...parentMenu,
                                            title: cat.description
                                              .split("&amp;eacute;")
                                              .join("é")
                                              .split("&#39;")
                                              .join("'"),
                                            menu: showMenuState,
                                            parent: [
                                              ...parentMenu.parent,
                                              showMenuState
                                            ]
                                          });
                                        } else {
                                          setParentMenu({
                                            ...parentMenu,
                                            subTitle: cat.description
                                              .split("&amp;eacute;")
                                              .join("é")
                                              .split("&#39;")
                                              .join("'"),
                                            parent: [
                                              ...parentMenu.parent,
                                              showMenuState
                                            ]
                                          });
                                        }
                                        setShowMenuState(cat.cid);
                                      } else if (
                                        e.keyCode === 40 &&
                                        menuState[showMenuState][index + 1] &&
                                        Object.keys(
                                          menuState[showMenuState][index + 1]
                                        ).includes("cid")
                                      ) {
                                        if (
                                          testElRefs.current[
                                            menuState[showMenuState][index + 1]
                                              .cid
                                          ]
                                        ) {
                                          testElRefs.current[
                                            menuState[showMenuState][index + 1]
                                              .cid
                                          ].focus();
                                        }
                                      } else if (e.keyCode === 38) {
                                        if (
                                          index === 0 &&
                                          headRef &&
                                          headRef.current
                                        ) {
                                          headRef.current.focus();
                                        } else if (
                                          menuState[showMenuState][index - 1] &&
                                          Object.keys(
                                            menuState[showMenuState][index - 1]
                                          ).includes("cid")
                                        ) {
                                          if (
                                            testElRefs.current[
                                              menuState[showMenuState][
                                                index - 1
                                              ].cid
                                            ]
                                          ) {
                                            testElRefs.current[
                                              menuState[showMenuState][
                                                index - 1
                                              ].cid
                                            ].focus();
                                          }
                                        }
                                      } else if (e.keyCode === 37) {
                                        e.preventDefault();
                                        if (showMenuState !== "main") {
                                          e.preventDefault();
                                          let parents = [...parentMenu.parent];
                                          setShowMenuState(
                                            parentMenu.parent[
                                              parentMenu.parent.length - 1
                                            ]
                                          );
                                          parents.pop();

                                          setParentMenu({
                                            ...parentMenu,
                                            menu:
                                              parentMenu.parent[
                                                parentMenu.parent.length - 1
                                              ],
                                            parent: parents
                                          });
                                        }
                                      } else if (
                                        e.keyCode === 40 &&
                                        index ===
                                          menuState[showMenuState].length - 1
                                      ) {
                                        lastRef2.current.focus();
                                      }
                                    }}
                                    className="menuCat"
                                    to={"/" + tempUrl}
                                    tabIndex={navMenuOpen ? "0" : "-1"}
                                  >
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: catName
                                      }}
                                    />
                                  </Link>
                                  {Object.keys(cat).includes("childs") &&
                                    cat.childs &&
                                    cat.childs.length > 0 && (
                                      <i
                                        className="material-icons focusIcon"
                                        style={{ color: "white" }}
                                        tabIndex={navMenuOpen ? "0" : "-1"}
                                        onClick={e => {
                                          if (showMenuState !== "main") {
                                            setParentMenu({
                                              ...parentMenu,
                                              title: cat.description
                                                .split("&amp;eacute;")
                                                .join("é")
                                                .split("&#39;")
                                                .join("'"),
                                              menu: showMenuState,
                                              parent: [
                                                ...parentMenu.parent,
                                                showMenuState
                                              ]
                                            });
                                          } else {
                                            setParentMenu({
                                              ...parentMenu,
                                              subTitle: cat.description
                                                .split("&amp;eacute;")
                                                .join("é")
                                                .split("&#39;")
                                                .join("'"),
                                              parent: [
                                                ...parentMenu.parent,
                                                showMenuState
                                              ]
                                            });
                                          }
                                          setShowMenuState(cat.cid);
                                        }}
                                        onKeyDown={e => {
                                          if (e.keyCode === 13) {
                                            e.preventDefault();
                                            if (showMenuState !== "main") {
                                              setParentMenu({
                                                ...parentMenu,
                                                title: cat.description
                                                  .split("&amp;eacute;")
                                                  .join("é")
                                                  .split("&#39;")
                                                  .join("'"),
                                                menu: showMenuState,
                                                parent: [
                                                  ...parentMenu.parent,
                                                  showMenuState
                                                ]
                                              });
                                            } else {
                                              setParentMenu({
                                                ...parentMenu,
                                                subTitle: cat.description
                                                  .split("&amp;eacute;")
                                                  .join("é")
                                                  .split("&#39;")
                                                  .join("'"),
                                                parent: [
                                                  ...parentMenu.parent,
                                                  showMenuState
                                                ]
                                              });
                                            }
                                            setShowMenuState(cat.cid);
                                          } else if (e.keyCode === 27) {
                                            e.preventDefault();
                                            toggleDrawer();
                                            setShowMenuState("main");
                                            setParentMenu({
                                              title: "Main Menu",
                                              menu: "main",
                                              parent: [],
                                              subTitle: ""
                                            });

                                            closeRef.current.focus();
                                          }
                                        }}
                                      >
                                        chevron_right
                                      </i>
                                    )}
                                </li>
                              );
                            })}
                        </ul>
                        <ul className="staticMenu">
                          <li
                            className="menuCat"
                            onClick={() => {
                              toggleDrawer();
                              const btn = document.getElementById(
                                "login-icon-btn"
                              );
                              if (btn) btn.click();
                            }}
                            onKeyDown={e => {
                              if (e.keyCode === 13) {
                                e.preventDefault();

                                toggleDrawer();
                                const btn = document.getElementById(
                                  "login-icon-btn"
                                );
                                if (btn) btn.click();
                              } else if (e.keyCode === 27) {
                                e.preventDefault();
                                toggleDrawer();
                                setShowMenuState("main");
                                setParentMenu({
                                  title: "Main Menu",
                                  menu: "main",
                                  parent: [],
                                  subTitle: ""
                                });

                                closeRef.current.focus();
                              } else if (e.keyCode === 40 && navMenuOpen) {
                                e.preventDefault();

                                lastRef1.current.focus();
                              } else if (e.keyCode === 38 && navMenuOpen) {
                                e.preventDefault();
                                testElRefs.current[
                                  menuState[showMenuState][
                                    menuState[showMenuState].length - 1
                                  ].cid
                                ].focus();
                              }
                            }}
                            tabIndex={navMenuOpen ? "0" : "-1"}
                            ref={lastRef2}
                          >
                            <span>
                              {loginNameState
                                ? "My Account"
                                : translate("js.header.login").toUpperCase()}
                            </span>
                          </li>

                          <li
                            onClick={() => {
                              toggleDrawer();
                              const btn = document.getElementById(
                                "cart-icon-btn"
                              );
                              if (btn) btn.click();
                            }}
                            onKeyDown={e => {
                              if (e.keyCode === 13) {
                                e.preventDefault();
                                toggleDrawer();
                                const btn = document.getElementById(
                                  "cart-icon-btn"
                                );
                                if (btn) btn.click();
                              } else if (e.keyCode === 27) {
                                e.preventDefault();
                                toggleDrawer();
                                setShowMenuState("main");
                                setParentMenu({
                                  title: "Main Menu",
                                  menu: "main",
                                  parent: [],
                                  subTitle: ""
                                });

                                closeRef.current.focus();
                              } else if (e.keyCode === 40 && navMenuOpen) {
                                e.preventDefault();

                                lastRef.current.focus();
                              } else if (e.keyCode === 38 && navMenuOpen) {
                                e.preventDefault();
                                lastRef2.current.focus();
                              }
                            }}
                            tabIndex={navMenuOpen ? "0" : "-1"}
                            className="menuCat"
                            ref={lastRef1}
                          >
                            <span>Mon Panier</span>
                          </li>
                          <li
                            onClick={() => {
                              toggleDrawer();
                              const btn = document.getElementById(
                                "wishlist-icon-btn"
                              );
                              if (btn) btn.click();
                            }}
                            onKeyDown={e => {
                              if (e.keyCode === 13) {
                                toggleDrawer();
                                const btn = document.getElementById(
                                  "wishlist-icon-btn"
                                );
                                if (btn) btn.click();
                              } else if (!e.shiftKey && e.keyCode === 9) {
                                e.preventDefault();
                                closeRef.current.focus();
                              } else if (e.keyCode === 40 && navMenuOpen) {
                                e.preventDefault();

                                closeRef.current.focus();
                              } else if (e.keyCode === 38 && navMenuOpen) {
                                e.preventDefault();
                                lastRef1.current.focus();
                              }
                            }}
                            tabIndex={navMenuOpen ? "0" : "-1"}
                            className="menuCat"
                            ref={lastRef}
                          >
                            <span>Ma liste d'envies</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : null}

            <div className="logo-container">
              <Link to={"/"} className="brand-logo logo-i">
                <div className="menu-logo-wrapper">
                  <img
                    className="img-responsive"
                    src={logo}
                    alt="Logo deluxe.ca"
                  />
                </div>
              </Link>
            </div>
            {!isMobileState ? <SearchBar /> : ""}
            <div className="nav-widget-container">
              <div
                onClick={() => {
                  navMenuOpen && setNavMenuOpen(false);
                }}
                className="nav-icons-container"
              >
                <div className="nav-icons-container">
                  {/* <div className="icon-container">
                    <LanguageSelect />
                  </div> */}
                  {!isMobileState ? (
                    <div
                      className="icon-container seller-icon-container"
                      onClick={handleOpenModalToChangeDistance}
                    >
                      {/* <div className="icon-wrapper">
                        <span className="material-icons">location_on</span>
                      </div> */}
                    </div>
                  ) : (
                    <div className="icon-container seller-icon-container">
                      <div className="icon-wrapper">
                        <span
                          //id="mobile-nav-icon"
                          open={navMenuOpen}
                          className="material-icons mobile-nav-icon focusIconFr"
                          onClick={toggleDrawer}
                          onKeyDown={e => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                              toggleDrawer();
                              setShowMenuState("main");
                              setParentMenu({
                                title: "Main Menu",
                                menu: "main",
                                parent: [],
                                subTitle: ""
                              });
                              if (searchRef && searchRef.current) {
                                searchRef.current.focus();
                              }
                            }
                          }}
                          tabIndex="0"
                        >
                          search
                        </span>
                      </div>
                    </div>
                  )}

                  <WishList />

                  <div
                    id="cart-icon-btn"
                    className="icon-container"
                    onClick={
                      langCode === "ar"
                        ? toggleDrawerMini("left", true)
                        : toggleDrawerMini("right", true)
                    }
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        setState({ ...state, right: true });
                      }
                    }}
                    tabIndex="0"
                  >
                    <div className="icon-wrapper">
                      <img src={Bag} alt="shopping bag" />
                      <span
                        className="minicart-new-count"
                        style={{
                          display: basketCountState ? "" : "none"
                        }}
                      >
                        {basketCountState || 0}
                      </span>
                    </div>
                    <div className="icon-text">
                      <span className="icon-action-text">
                        {renderMyCartTextOrTheSumOfTheCart()}
                      </span>
                    </div>
                  </div>

                  <Drawer
                    anchor={langCode === "ar" ? "left" : "right"}
                    open={langCode === "ar" ? state.left : state.right}
                    onClose={
                      langCode === "ar"
                        ? toggleDrawerMini("left", false)
                        : toggleDrawerMini("right", false)
                    }
                  >
                    {langCode === "ar" ? sideList("left") : sideList("right")}
                  </Drawer>

                  <Profile
                    modalOpenedByIcon={loginModalOpened}
                    setModalOpened={setLoginModalOpened}
                  />

                  {/* <Link
                    className="icon-container seller-icon-container"
                    to={"/seller-registration"}
                  >
                    <div className="icon-wrapper">
                      <span className="material-icons">storefront</span>
                    </div>
                    <div className="login-text-container">
                      <span className="icon-action-text">
                        Seller
                        <br />
                        Sign Up
                      </span>
                    </div>
                  </Link> */}
                </div>
              </div>
              {/* {!isMobileState ? (
                <div className="nav-promo-container">
                  <form>
                    <label for="promo-code" style={{ display: "block" }}>
                      CODE PROMOTIONNEL
                    </label>
                    <input
                      type="text"
                      name="promo-code"
                      id="promo-code"
                      placeholder="Entrer le code promotionnel..."
                      style={{
                        width: "300px",
                        marginBottom: "10px",
                        borderRadius: "5px"
                      }}
                    ></input>
                  </form>
                </div>
              ) : (
                ""
              )} */}
            </div>
          </div>
        </div>
      </nav>

      <nav
        className={isMobileState ? "nav-extended hidden" : "nav-extended"}
        style={{
          boxShadow: "0px 2px 5px #E7E7E7",
          background: "#fff",

          borderTop: "2px solid #E7E7E7"
        }}
      >
        <div className="nav-wrapper">
          <div className="header-container">
            <NavMenu data={data} />
          </div>
        </div>
      </nav>
    </>
  );
}
